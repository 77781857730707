import styled from "styled-components"

import { media } from "@variables"

export const Wrap = styled.section`
    width: 100%;
    padding: 65px 0 0 0;

    ${media.desktop} {
        padding: 100px 0 0 0;
    }
`

export const Head = styled.header`
    margin: 0 auto;
    display: block;
    text-align: center;

    h1 {
        padding-bottom: 10px;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    padding: 60px 0;
    justify-content: space-around;

    ${media.desktop} {
        padding: 80px 0 100px 0;
    }
    ${media.xl} {
        justify-content: space-between;
    }
`

export const Article = styled.article`
    flex: 0 0 100%;
    margin-bottom: 54px;

    ${media.tablet} {
        flex: 0 0 47%;
        margin-bottom: 84px;
    }

    ${media.xl} {
        flex: 0 0 31%;
    }

    img {
        overflow: hidden;
        border-radius: 4px;
    }

    a {
        text-decoration: none;
    }
`

export const ArticleTitle = styled.h4`
    padding-top: 30px;
    line-height: normal;
`
