import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import t from "@translations"
import { AllMarkdownRemarkQuery } from "@graphql"
import Container from "@components/shared/Container"
import { Wrap, Head, Row, Article, ArticleTitle } from "./styled"

type Stories = AllMarkdownRemarkQuery["allMarkdownRemark"]["nodes"]
type Story = Stories[0]

type Props = {
    data: Stories
}

const Stories = ({ data }: Props) => {
    return (
        <Wrap>
            <Head>
                <h1>{t["stories-title"]}</h1>
                <p>{t["stories-subtitle"]}</p>
            </Head>

            <Container>
                <Row>
                    {data.map((story: Story) => {
                        const slug = story?.fields?.slug || ""
                        const title = story?.frontmatter?.title || ""
                        const image =
                            story?.frontmatter?.image?.childImageSharp
                                ?.gatsbyImageData

                        return (
                            <Article
                                itemScope
                                key={slug}
                                itemType="http://schema.org/Article"
                            >
                                <Link itemProp="url" to={slug}>
                                    <GatsbyImage alt={title} image={image}/>
                                    <ArticleTitle>
                                        <span itemProp="headline">{title}</span>
                                    </ArticleTitle>
                                </Link>
                            </Article>
                        )
                    })}
                </Row>
            </Container>
        </Wrap>
    )
}

export default Stories
