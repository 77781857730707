import * as React from "react"
import { graphql } from "gatsby"

import t from "@translations"
import Seo from "@components/seo"
import Layout from "@components/layout"
import Stories from "@sections/Stories"
import { AllMarkdownRemarkQuery } from "@graphql"

type Props = {
    location: Location
    data: AllMarkdownRemarkQuery
}

const StoriesPage = ({ data, location }: Props) => {
    const stories = data.allMarkdownRemark.nodes

    if (stories.length === 0) {
        return (
            <Layout location={location}>
                <Seo title={t["seo-stories-title"]} />
                <p>{t["empty-stories"]}</p>
            </Layout>
        )
    }

    return (
        <Layout location={location}>
            <Seo title={t["seo-stories-title"]} />
            <Stories data={stories} />
        </Layout>
    )
}

export default StoriesPage

export const pageQuery = graphql`
    query AllMarkdownRemark {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                    description
                    date(formatString: "MMMM DD, YYYY")
                    image {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, width: 520)
                        }
                    }
                }
            }
        }
    }
`
